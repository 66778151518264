import { useRef } from 'react';
import { useRouter } from 'next/router';
import { tw } from 'twind';

import { Ga4Data } from 'hooks/usePromotionSelect';
import { SanityImage } from 'ui/content/SanityImage';
import { Link } from 'ui/elements/Link';
import { Markdown } from 'ui/elements/Markdown';
import { usePromotionView } from 'hooks/usePromotionView';
import { InfoBannerWithCategoryExclusion } from 'groq/documents/SecondGlobalBannerDocument';

import { CountdownClock } from './CountdownClock';

type InfoBannerGroqProps = InfoBannerWithCategoryExclusion['banner'] &
  Omit<InfoBannerWithCategoryExclusion, 'banner'>;

export type InfoBannerProps = InfoBannerGroqProps & {
  campaignId?: string;
  priority?: boolean;
};

export const InfoBanner = (props: InfoBannerProps) => {
  const { asPath } = useRouter();
  const infoBannerRef = useRef(null);

  const promotion_name = props.header || '';

  const gaTrackData = {
    id: props.puid?.current || '',
    name: promotion_name,
    creative: 'promo-banner',
    position: '0',
    campaign: props.campaignId || '',
  };

  const creative_name = props._type || 'InfoBanner';
  const promotion_id = props._id;

  const extendedGaTrackData = {
    ...gaTrackData,
    promotion_id,
    creative_name,
    cta_links:
      props.ctas && props.ctas.length > 0 ? props.ctas.map(c => c.link) : [],
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    infoBannerRef,
    extendedGaTrackData
  );

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: props.ctas && props.ctas.length > 0 ? props.ctas[0].link : '',
    cta_click: undefined,
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  const responsiveClasses = props.targetDevices?.map(device => {
    return `${device}:block`;
  });

  return (
    <section
      data-link-loc="banner"
      data-test-id="banner"
      className={tw(
        `relative flex p-4 pb-5 overflow-hidden bg-[${
          props.backgroundColor ?? '#fafafa'
        }] my-0 mx-[calc(50%-50vw)]`,
        'hidden empty:hidden',
        responsiveClasses
      )}
      ref={infoBannerRef}
    >
      <SanityImage
        mobileSource={props.mobileBackgroundImage}
        source={props.optionalBackgroundImage}
        priority={props.priority}
      />
      {props.ctas && props.ctas[0] && (
        <Link
          query={props.ctas[0].query}
          newtab={!!props.ctas[0].newtab}
          href={props.ctas[0].link}
          className="absolute inset-0 h-full w-full"
          aria-hidden
          tabIndex={-1}
          gaBannerData={gaTrackData}
          ga4Data={ga4Data}
        />
      )}
      <div
        className={tw(
          `relative m-auto flex flex-col items-center text-center text-[${
            props.textColor ?? '#000'
          }]`
        )}
      >
        {props.header && (
          <p className="text-lg desktop:text-xl font-bold leading-tight mb-0">
            <Markdown content={props.header} />
          </p>
        )}
        <div
          className={tw([
            'flex flex-col items-center',
            props.showCountdownBeneathSubheading && 'flex-col-reverse',
          ])}
        >
          {props.countdownClock && (
            <CountdownClock
              {...props.countdownClock}
              config={{
                containerClassName: 'text-xl space-x-1',
              }}
            />
          )}
          {props.copy && (
            <h2 className="mobile:mt-1 text-sm desktop:text-base font-bold">
              <Markdown content={props.copy} />
            </h2>
          )}
        </div>
        {props.ctas && props.ctas.length > 0 && (
          <div className="flex space-x-2">
            {props.ctas.map(cta => (
              <Link
                key={cta.id}
                href={cta.link}
                query={cta.query}
                newtab={!!cta.newtab}
                popup={!!cta.popup}
                gaBannerData={gaTrackData}
                underlined
                ga4Data={{
                  ...ga4Data,
                  link_url: cta.link,
                  cta_click: cta.title || '',
                }}
              >
                {cta.title}
              </Link>
            ))}
          </div>
        )}
        {props.footnote && (
          <h3 className="text-xs mt-1">
            <Markdown content={props.footnote} />
          </h3>
        )}
      </div>
    </section>
  );
};
