// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/video.ts

import { CloudinaryVideo } from '__generated__/graphql-sanity';

export type SanityVideoObjectType = {
  source: string;
  autoplay: boolean;
  controls: boolean;
  loop: boolean;
  mute: boolean;
  cloudinaryVideo: CloudinaryVideo;
};

export const SanityVideoObject = (field: string) => `
  ${field} {
    "source": coalesce(cloudinaryVideo.cdnUrl, source.asset->url),
    autoplay,
    loop,
    mute,
    cloudinaryVideo,
  }
`;
