import { useRef } from 'react';
import { useRouter } from 'next/router';
import { tw } from 'twind/style';

import { CallToActions } from 'ui/content/CallToActions';
import { ImageOverlay, ImageOverlayProps } from 'ui/elements/ImageOverlay';
import { SanityImage } from 'ui/content/SanityImage';
import { extractProductVariants } from 'utils/extractProductVariants';
import { whiteToBlack } from 'utils/whiteToBlack';
import { Markdown } from 'ui/elements/Markdown';
import {
  CountdownClock as CountdownClockType,
  Cta,
  Maybe,
  TrendingTile as TrendingTileType,
  useRecommendationsWithDetailsQuery,
} from '__generated__/graphql';
import { ResponsiveProductImage } from 'ui/components/ResponsiveProductImage';
import { LinkType, SLOT_LINK_TYPE, isServer } from 'utils/constants';
import { usePromotionView, GaTrackData } from 'hooks/usePromotionView';
import { useGA4Events } from 'hooks/useGA4Events';
import { slotLink } from 'utils/urls';
import { BannerLinkType } from 'utils/types';
import { Ga4Data } from 'hooks/usePromotionSelect';

import { CountdownClock } from './CountdownClock';
import { LinkOrTag } from './LinkOrTag';

export const SIDE_BY_SIDE = 'SIDE_BY_SIDE';
export const TRENDING_INLINE = 'TRENDING_INLINE';

type TrendingTileSanityType = TrendingTileType & {
  link?: BannerLinkType;
  linkType?: LinkType;
};

type TrendingTileProps = TrendingTileSanityType & {
  inSideBySide?: boolean;
  ga4Data?: Ga4Data;
};

export const TrendingTile = ({
  inSideBySide = false,
  ...tile
}: TrendingTileProps) => {
  const { asPath } = useRouter();

  const { countdownClock } = tile;
  const overlayTextColor = `${
    tile.textColor ? `text-[${tile.textColor}]` : 'text-white'
  }`;

  const trendingTileRef = useRef(null);

  const promotion_name = tile.header || tile.ctas?.[0]?.title || '';

  const gaTrackData = {
    id: tile.puid?.current || '',
    name: promotion_name,
    creative: 'trending-tile',
    position: '',
  };

  const navigateTo = slotLink(tile?.linkType, tile?.ctas, tile?.link);

  const promotion_id = tile.id || '';
  const creative_name = tile.__typename || 'TrendingTile';

  const extendedGaTrackData = {
    ...gaTrackData,
    promotion_id,
    creative_name,
    cta_links:
      tile?.ctas && tile.ctas.length > 0 ? tile.ctas.map(c => c.link) : [],
  };

  const { promotionTrackerPosition, ga4PromotionTrackerPosition } =
    usePromotionView(trendingTileRef, extendedGaTrackData);

  if (promotionTrackerPosition) {
    gaTrackData.position = promotionTrackerPosition;
  }

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <LinkOrTag
      dataTestId="trending-tile"
      ref={trendingTileRef}
      newtab={!!navigateTo?.newtab}
      query={navigateTo?.query}
      href={navigateTo?.link}
      gaBannerData={gaTrackData}
      className={tw('relative w-full')}
      linkType={tile.linkType!}
      ga4Data={
        tile.ga4Data
          ? {
              ...tile.ga4Data,
              promotion_name: tile.header || '',
              link_url: navigateTo?.link || '',
            }
          : {
              ...ga4Data,
              link_url: navigateTo?.link || '',
            }
      }
    >
      {tile.einsteinRecommender &&
        tile.backgroundType === 'einsteinRecommenderType' && (
          <TrendingTileRecommenderBackground
            altText={tile.einsteinRecommenderAltText}
            pageType={tile.einsteinRecommender}
          />
        )}
      {tile.backgroundImage && tile.backgroundType === 'chosenImageType' && (
        <div className="relative" style={{ paddingTop: '100%' }}>
          <SanityImage source={tile!.backgroundImage} />
          <ImageOverlay
            {...(tile.backgroundOverlay as ImageOverlayProps)}
            className="block mobile:hidden"
          />
        </div>
      )}
      <div
        className={tw(
          'mt-3 w-full',
          (tile.overlay || inSideBySide) &&
            `absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 ${overlayTextColor}`,
          inSideBySide && 'flex flex-col items-center'
        )}
      >
        <TileHeaderAndCopy
          copy={tile.copy}
          header={tile.header}
          inSideBySide={inSideBySide}
          countdownClock={countdownClock}
        />
        {tile.linkType === SLOT_LINK_TYPE.CTA && (
          <TileCtas
            inSideBySide={inSideBySide}
            ctas={tile.ctas}
            gaBannerData={gaTrackData}
            ga4Data={
              tile.ga4Data
                ? {
                    ...tile.ga4Data,
                    promotion_name: tile.header || '',
                  }
                : ga4Data
            }
          />
        )}
      </div>
    </LinkOrTag>
  );
};

const TileCtas = ({
  ctas,
  inSideBySide,
  gaBannerData,
  ga4Data,
}: {
  ctas: Maybe<Cta[]> | undefined;
  inSideBySide: boolean;
  gaBannerData?: GaTrackData;
  ga4Data?: Ga4Data;
}) => {
  if (inSideBySide) {
    return (
      <>
        <div className="hidden md:flex flex-wrap flex-gap-4 mt-3 relative">
          <CallToActions
            ctas={ctas}
            gaBannerData={gaBannerData}
            ga4Data={ga4Data}
          />
        </div>
        <div className="md:hidden flex tablet:flex flex-wrap justify-center flex-gap-2 xs:flex-gap-4 mt-3 relative">
          <CallToActions
            ctas={ctas?.map(whiteToBlack)}
            gaBannerData={gaBannerData}
            ga4Data={ga4Data}
          />
        </div>
      </>
    );
  }
  return (
    <div className="relative mt-3 flex flex-col space-y-3 max-w-64 mx-auto">
      <CallToActions
        ctas={ctas}
        gaBannerData={gaBannerData}
        ga4Data={ga4Data}
      />
    </div>
  );
};

const TrendingTileRecommenderBackground = ({
  altText,
  pageType = '',
}: {
  altText?: Maybe<string> | undefined;
  pageType?: string;
}) => {
  const { fireClickProductImage } = useGA4Events();

  const [result] = useRecommendationsWithDetailsQuery({
    variables: {
      pageType,
    },
    pause: isServer,
  });

  const product = extractProductVariants(
    result.data?.recommendationsWithDetails.recommendations
  )[0];

  return product?.images[0].href ? (
    <div className="relative">
      <ResponsiveProductImage
        className="w-full"
        src={product?.images[0].href}
        widths={[750, 900, 1050, 1200, 1350, 1500]}
        sizes={{
          mobile: '100vw',
          tablet: '33vw',
          desktop: '33vw',
        }}
        width={600}
        height={600}
        alt={altText || product?.images[0].alt}
        onClick={() => fireClickProductImage(product?.images[0].href)}
      />
    </div>
  ) : (
    <div className="aspect-1-1 bg-puma-black-600"></div>
  );
};

const TileHeaderAndCopy = ({
  copy,
  header,
  inSideBySide,
  countdownClock,
}: {
  copy?: Maybe<string>;
  header?: Maybe<string>;
  inSideBySide?: boolean;
  countdownClock?: Maybe<CountdownClockType>;
}) => {
  const clock = countdownClock ? (
    <CountdownClock
      {...countdownClock}
      config={{
        timeUnitClassName: 'text-base font-normal',
        containerClassName: 'space-x-1 justify-center',
      }}
    />
  ) : null;
  if (inSideBySide) {
    return (
      <>
        {header && (
          <h1 className="font-bold text-3xl sm:text-4xl relative">
            <Markdown content={header} />
          </h1>
        )}
        {clock}
        {!clock && copy && (
          <p className="font-bold relative">
            <Markdown content={copy} />
          </p>
        )}
      </>
    );
  }
  // Default - such as rendering in TrendingInline
  return (
    <div className="text-center">
      {header && (
        <h2 className="text-xl font-bold">
          <Markdown content={header} />
        </h2>
      )}
      {clock}
      {!clock && copy && (
        <p className="text-xs">
          <Markdown content={copy} />
        </p>
      )}
    </div>
  );
};
