import { OperationResult, UseQueryArgs, UseQueryState } from 'urql';
import { useCallback, useMemo } from 'react';

import {
  ProductCartItem,
  ItemToBeAddedToCart,
  UpdateItemInCartInput,
  RemoveItemFromCartInput,
  useCartQuery,
  CartQuery,
  CartQueryVariables,
  useAddItemsToCartMutation,
  useRemoveItemFromCartMutation,
  useUpdateItemInCartMutation,
  AddItemsToCartMutation,
  UpdateItemInCartMutation,
  RemoveItemFromCartMutation,
} from '__generated__/graphql';
import { isServer } from 'utils/constants';
import {
  AnalyticsEvents,
  event,
  transformVariantProductToAnalyticsProduct,
  transformListProductToAnalyticsItem,
  getPageType,
} from 'utils/analytics';
import { Dimensions } from 'types/analyticTypes';
import { ActiveCart } from 'types/cart';
import { trackAddToCartInBloomreach } from 'utils/bloomreach';
import { getGa4Item, setGa4ItemInMap } from 'utils/ga4Items';

import { useMiniCart } from './useMiniCart';
import { useSiteConfig } from './useSiteConfig';
import { useEmarsysWebExtend } from './useEmarsysWebExtend';
import { useGA4Events } from './useGA4Events';
import { usePageEventsContext } from './usePageEventsContext';

export type AddToCartVariables = ItemToBeAddedToCart & {
  swatch: string;
  size?: string;
};

type UpdateInCartVariables = Omit<UpdateItemInCartInput, 'basketId'>;

type RemoveFromCartVariables = Omit<RemoveItemFromCartInput, 'basketId'>;

export type AddedProduct = ProductCartItem & {
  addedQuantity: number;
};

type AdditionalGAProductData = { [Dimensions.d100]?: string };

type UseCartResult = {
  cart: ActiveCart;
  cartResult: UseQueryState<CartQuery>;
  addToCart: (
    variables: AddToCartVariables[] | AddToCartVariables,
    additionalGAProductData?: AdditionalGAProductData,
    showMiniCart?: boolean,
    currentUrl?: string
  ) => Promise<OperationResult<AddItemsToCartMutation>>;
  updateInCart: (
    variables: UpdateInCartVariables
  ) => Promise<OperationResult<UpdateItemInCartMutation>>;
  removeFromCart: (
    variables: RemoveFromCartVariables
  ) => Promise<OperationResult<RemoveItemFromCartMutation>>;
};

export const useCart = (
  cartQueryOptions: Omit<UseQueryArgs<CartQueryVariables>, 'query'> = {}
): UseCartResult => {
  const { currency } = useSiteConfig();
  const { openMiniCart } = useMiniCart();
  const { trackCartUpdate } = useEmarsysWebExtend();
  const { pageviewEventHasFired } = usePageEventsContext();

  const { imageType, imageNumber } = useGA4Events();

  const [cartResult] = useCartQuery({
    ...cartQueryOptions,
    // we always want to avoid invoking this query on the server, even when
    // overriding pause conditions are included
    pause: isServer || (cartQueryOptions.pause ?? false),
  });

  const cart = cartResult.data?.activeCart;
  const cartId = cart?.id || '';

  const [, addItemsToCart] = useAddItemsToCartMutation();

  const addToCart = useCallback<UseCartResult['addToCart']>(
    async (
      items: AddToCartVariables[] | AddToCartVariables,
      additionalGAProductData?: AdditionalGAProductData,
      showMiniCart = true,
      currentUrl = '/'
    ) => {
      const itemsArray = Array.isArray(items) ? items : [items];
      const productReferrer = additionalGAProductData?.[Dimensions.d100]
        ? additionalGAProductData
        : { [Dimensions.d100]: '' };

      const itemsToAdd = itemsArray.reduce(
        (acc, { swatch, size, productId, ...rest }) => ({
          ...acc,
          [productId]: { productId, ...rest },
        }),
        {}
      );
      const res = await addItemsToCart({
        input: {
          items: Object.values(itemsToAdd),
          basketId: cartId,
        },
      });

      if (res.data?.addItemsToCart) {
        const addedProducts = res.data.addItemsToCart.products.reduce(
          (acc, item) => {
            if (
              item.__typename === 'ProductCartItem' &&
              itemsToAdd[item.product.id]
            ) {
              return [
                ...acc,
                {
                  ...item,
                  addedQuantity: itemsToAdd[item.product.id].quantity,
                } as AddedProduct,
              ];
            }
            return acc;
          },
          [] as AddedProduct[]
        );

        if (addedProducts) {
          if (showMiniCart) openMiniCart(addedProducts);
          trackCartUpdate();

          addedProducts.forEach(addedProduct => {
            event(AnalyticsEvents.ADD_TO_CART, {
              ecommerce: {
                currencyCode: currency.code,
                add: {
                  products: [
                    {
                      ...transformVariantProductToAnalyticsProduct(
                        addedProduct.product,
                        true
                      ),
                      price: addedProduct.inCartPrice,
                      quantity: addedProduct.addedQuantity,
                      variant: addedProduct.product.id,
                      brand: addedProduct.product.brand || 'PUMA',
                      category: addedProduct.product.primaryCategoryId,
                      ...productReferrer,
                    },
                  ],
                },
              },
            });

            if (pageviewEventHasFired) {
              const ga4Item = getGa4Item(addedProduct.product?.styleNumber);

              if (ga4Item) {
                const modifiedGa4Item = { ...ga4Item, cart: '1' };
                setGa4ItemInMap(
                  addedProduct.product?.styleNumber,
                  modifiedGa4Item,
                  true
                );
              }

              const value =
                Math.round(
                  addedProduct.inCartPrice * addedProduct.addedQuantity * 100
                ) / 100;
              const analyticsItem = transformListProductToAnalyticsItem({
                product: addedProduct.product,
                currency: currency.code,
                quantity: addedProduct.addedQuantity,
                categories: {
                  item_category: addedProduct.product.primaryCategoryId,
                },
                size: addedProduct.product.size.label,
                itemListId: ga4Item?.lid,
                itemListName: ga4Item?.lname,
                index: ga4Item?.idx,
                creativeName: ga4Item?.cname,
                creativeSlot: ga4Item?.cslot,
                promotionId: ga4Item?.pid,
                promotionName: ga4Item?.pname,
              });
              event(AnalyticsEvents.GA4EC_AddItemToCart, {
                event_name: AnalyticsEvents.ADD_ITEM_LIST_TO_CART,
                ecommerce: {
                  item_list_id: ga4Item?.lid,
                  item_list_name: ga4Item?.lname,
                  currency: currency.code,
                  value,
                  item_id_ep:
                    getPageType(currentUrl) === 'pdp'
                      ? `${addedProduct.product.masterId}_${addedProduct.product.colorValue}`
                      : undefined,
                  item_name_ep:
                    getPageType(currentUrl) === 'pdp'
                      ? addedProduct.product.name
                      : undefined,
                  image_type: imageType,
                  image_number: imageNumber,
                  items: [analyticsItem],
                },
              });
            }
          });

          addedProducts.forEach(item => {
            trackAddToCartInBloomreach({
              productId: `${item.product.masterId}_${item.product.colorValue}`,
              sku: item.product.ean || '',
              currency: currency.code,
            });
          });
        }
      }
      return res;
    },
    [
      addItemsToCart,
      cartId,
      currency.code,
      openMiniCart,
      trackCartUpdate,
      imageType,
      imageNumber,
      pageviewEventHasFired,
    ]
  );

  const [, updateItemInCart] = useUpdateItemInCartMutation();

  const updateInCart = useCallback<UseCartResult['updateInCart']>(
    async (variables: UpdateInCartVariables) => {
      {
        const resp = await updateItemInCart({
          input: {
            basketId: cartId,
            ...variables,
          },
        });

        trackCartUpdate();

        return resp;
      }
    },
    [cartId, trackCartUpdate, updateItemInCart]
  );

  const [, removeItemFromCart] = useRemoveItemFromCartMutation();

  const removeFromCart = useCallback<UseCartResult['removeFromCart']>(
    async (variables: RemoveFromCartVariables) => {
      const resp = await removeItemFromCart({
        input: { basketId: cartId, ...variables },
      });
      trackCartUpdate();
      return resp;
    },
    [cartId, removeItemFromCart, trackCartUpdate]
  );

  return useMemo(() => {
    return { cart, cartResult, addToCart, updateInCart, removeFromCart };
  }, [cart, cartResult, addToCart, updateInCart, removeFromCart]);
};
