import { useMemo } from 'react';

import { Eager, Maybe } from '__generated__/graphql-sanity';

interface UseOptimizedVideoDisplayProps {
  optimizedVideoObjects: Maybe<Maybe<Eager>[]> | undefined;
  width: number;
  height: number;
  hasOptimizedVideo: boolean;
}

export const useOptimizedVideoDisplay = ({
  optimizedVideoObjects,
  width,
  height,
  hasOptimizedVideo,
}: UseOptimizedVideoDisplayProps) => {
  const optimizedVideoDisplay = useMemo(() => {
    if (!hasOptimizedVideo || !optimizedVideoObjects) return;

    const eagerOptimized = optimizedVideoObjects
      .filter(eager => eager && eager.width && eager.height && eager.url)
      .map(eager => {
        const heightDiff = eager?.height ? Math.abs(eager.height - height) : 0;
        const widthDiff = eager?.width ? Math.abs(eager.width - width) : 0;
        const url = eager?.url ?? '';

        return {
          heightDiff,
          widthDiff,
          url,
        };
      });
    if (eagerOptimized.length === 0) return;

    const { url: closestUrl } = eagerOptimized.reduce((prev, curr) => {
      const prevDiff = prev.heightDiff + prev.widthDiff;
      const currDiff = curr.heightDiff + curr.widthDiff;
      return prevDiff < currDiff ? prev : curr;
    });

    return closestUrl;
  }, [height, width, optimizedVideoObjects, hasOptimizedVideo]);

  return optimizedVideoDisplay;
};

export default useOptimizedVideoDisplay;
