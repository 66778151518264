import { useRouter } from 'next/router';
import { css, tw } from 'twind/style';
import { Button, Link } from '@cyber-cats/uds/elements';
import { useMemo, useRef } from 'react';

import { AutomatedProductComparisonDocumentType } from 'groq/documents/AutomatedProductComparisonDocument';
import { usePriceFormatter } from 'hooks/usePriceFormatter';
import { useTranslate } from 'hooks/useTranslations';
import { buildProductUrl } from 'utils/buildProductUrl';
import { REFERRER_TYPE_VAL, isServer } from 'utils/constants';
import { removeHyphens } from 'utils/removeHyphens';
import { Maybe, useVariantsQuery } from '__generated__/graphql';
import { getMinimalNumber } from 'utils/getMinimalNumber';
import { isNumber } from 'utils/isNumber';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { useGA4Events } from 'hooks/useGA4Events';
import { setGa4ItemInMap } from 'utils/ga4Items';
import { useProductClickHandling } from 'hooks/useProductClickHandling';
import { setReferrerType } from 'utils/referrerParametersUtils';

const tableStyles = css`
  td {
    @apply px-4 py-4 text-center;
  }
  th {
    @apply w-80 min-w-80 px-6 py-4 text-left uppercase border-white border-r-[1vw];
  }
  & > tbody > :nth-child(odd) {
    @apply bg-puma-black-800;
  }
  & > tbody > :nth-child(even) {
    @apply bg-white;
  }
`;

export const AutomatedProductComparison = (
  props: AutomatedProductComparisonDocumentType
) => {
  const router = useRouter();
  const pdpMasterProductId = router.query.id as string | undefined;
  const [variantsResult] = useVariantsQuery({
    variables: {
      input: {
        styleNumberIds: props.products.map(p => `${p.masterId}_${p.color}`),
      },
      includeComparisonFields: true,
    },
    pause: props.products.length === 0 || isServer,
  });
  const comparisonProducts = useMemo(() => {
    const variants = variantsResult.data?.variants;
    if (!variants) return [];
    return [...variants]
      .sort(p => (p.masterId.toString() === pdpMasterProductId ? -1 : 1))
      .filter((p, idx) => (idx === 0 ? true : p.orderable));
  }, [variantsResult, pdpMasterProductId]);

  if (comparisonProducts.length < 2) return null;

  return (
    <AutomatedProductComparisonSection
      id={props._id}
      header={props.header}
      type={props._type}
      pdpMasterProductId={pdpMasterProductId}
      comparisonFields={props.comparisonFields}
      comparisonProducts={comparisonProducts}
    />
  );
};

const AutomatedProductComparisonSection = ({
  id,
  header,
  type,
  pdpMasterProductId,
  comparisonFields,
  comparisonProducts,
}: {
  id: string;
  header: Maybe<string> | undefined;
  type: string;
  pdpMasterProductId: string | undefined;
  comparisonFields: string[];
  comparisonProducts: Array<{
    __typename: 'Variant';
    id: string;
    masterId: string;
    percentageDiscountBadge?: number | null;
    name: string;
    orderable?: boolean | null;
    colorValue: string;
    technology?: string | null;
    runTerrain?: string | null;
    weight?: number | null;
    heelToToeDrop?: number | null;
    fastener?: string | null;
    pronation?: string | null;
    runnerType?: string | null;
    refinementCushioningLevel?: string | null;
    productPrice?: {
      __typename?: 'ProductPrice';
      price?: number | null;
      salePrice?: number | null;
      promotionPrice?: number | null;
    } | null;
    displayOutOfStock?: {
      __typename?: 'DisplayOutOfStock';
      displayValue?: string | null;
    } | null;
    images: Array<{ __typename?: 'ProductImage'; href: string; alt: string }>;
  }>;
}) => {
  const priceFormatter = usePriceFormatter();
  const t = useTranslate();

  const getComparisonFieldValueForProduct = (
    field: string,
    product: (typeof comparisonProducts)[0]
  ) => {
    switch (field) {
      case 'product-name':
        return product.name;
      case 'run-terrain':
        return product.runTerrain;
      case 'key-technology':
        return product.technology;
      case 'weight':
        if (product.weight) return `${product.weight}g`;
        break;
      case 'heel-to-toe-drop':
        if (isNumber(product.heelToToeDrop)) {
          return `${product.heelToToeDrop}mm`;
        }
        break;
      case 'price':
        const { price, salePrice, promotionPrice } = product.productPrice ?? {};
        const finalPrice = getMinimalNumber(price, salePrice, promotionPrice);
        return finalPrice ? priceFormatter(finalPrice) : null;
      case 'fastener':
        return product.fastener;
      case 'pronation':
        return product.pronation;
      case 'runner-type':
        return product.runnerType;
      case 'cushioning':
        return product.refinementCushioningLevel;
      default:
        return null;
    }
  };

  const automatedProductComparisonRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: id || '',
    name: header || '',
    creative: type || 'AutomatedProductComparison',
  };
  usePromotionView(automatedProductComparisonRef, gaTrackData, true);

  const { setProduct } = useGA4Events();

  // To differentiate in which carousel the click occurred.
  const setIsProductClicked = useProductClickHandling();

  const handleLinkClick = (product: any, i: number) => {
    setReferrerType(REFERRER_TYPE_VAL.AutomatedComparison);
    setGa4ItemInMap(`${product.masterId}_${product.colorValue}`, {
      lid: id,
      lname: type,
      idx: i,
    });
    setProduct(product);
  };

  const handleButtonClick = () => {
    setReferrerType(REFERRER_TYPE_VAL.AutomatedComparison);
    document
      .querySelector('#pdp-product-title')
      ?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section
      className="relative w-full"
      aria-label={header || undefined}
      ref={automatedProductComparisonRef}
    >
      <div className="max-w-full overflow-x-auto">
        <table className={tw('table-auto w-full relative', tableStyles)}>
          <tbody>
            <tr>
              <th className="text-3xl font-bold uppercase align-top pt-8!">
                {header}
              </th>
              {comparisonProducts.map(product => (
                <td key={product.id} className="p-0! bg-white!">
                  <img
                    src={
                      product.images[0]?.href ??
                      `https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_2000,h_2000/global/${product.masterId}/${product.colorValue}/sv01`
                    }
                    alt={product.images[0]?.alt ?? product.name}
                    className="mx-auto! h-40 w-40"
                  />
                </td>
              ))}
            </tr>
            {comparisonFields.map(field => (
              <tr key={field}>
                <th>{removeHyphens(field)}</th>
                {comparisonProducts.map(product => (
                  <td key={product.id}>
                    {getComparisonFieldValueForProduct(field, product) ?? '-'}
                  </td>
                ))}
              </tr>
            ))}
            <tr onClick={() => setIsProductClicked(true)}>
              <th className="bg-white!" />
              {comparisonProducts.map((product, i) => (
                <td key={product.id} className="p-0! bg-white! p-4!">
                  {product.masterId.toString() === pdpMasterProductId ? (
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => handleButtonClick()}
                      label={t('productComparisonCtaCurrent')}
                    />
                  ) : (
                    <Link
                      variant="secondary"
                      href={buildProductUrl(
                        product.masterId.toString(),
                        product.name,
                        { color: product.colorValue }
                      )}
                      onClick={() => handleLinkClick(product, i)}
                      label={t('productComparisonCta')}
                    />
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};
