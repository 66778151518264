import { useRef } from 'react';
import { useRouter } from 'next/router';

import { CallToActions } from 'ui/content/CallToActions';
import {
  CountdownClock,
  useIsCountdownAvailable,
} from 'ui/content/CountdownClock';
import { BodyContent as BodyContentType } from '__generated__/graphql';
import { alignmentToFlexDirection } from 'utils/alignmentToFlexDirection';
import { Markdown } from 'ui/elements/Markdown';
import { usePromotionView } from 'hooks/usePromotionView';
import { LinkType, SLOT_LINK_TYPE } from 'utils/constants';
import { slotLink } from 'utils/urls';
import { BannerLinkType } from 'utils/types';
import { Ga4Data } from 'hooks/usePromotionSelect';

import { LinkOrTag } from './LinkOrTag';
import { ContentHeading, ManageHeadingTag } from './ContentHeading';

export type BodyContentProps = BodyContentType & {
  campaignId?: string;
  headingTag?: ManageHeadingTag;
  link?: BannerLinkType;
  linkType?: LinkType;
  _id?: string;
};

export const BodyContent = ({
  campaignId,
  copy,
  countdownClock,
  ctas,
  header,
  horizontalAlignment,
  logo,
  puid,
  headingTag,
  link,
  linkType,
  _id,
  __typename,
}: BodyContentProps) => {
  const { asPath } = useRouter();
  const hAlign = alignmentToFlexDirection(horizontalAlignment || 'center');

  const bodyContentRef = useRef(null);

  const navigateTo = slotLink(linkType, ctas!, link);

  const creative_name = __typename || 'BodyContent';
  const promotion_id = _id || '';
  const promotion_name = header || '';

  const gaTrackData = {
    id: puid?.current || '',
    name: promotion_name,
    creative: 'body-content',
    position: '0',
    campaign: campaignId || '',
  };

  const extendedGaTrackData = {
    ...gaTrackData,
    promotion_id,
    creative_name,
    cta_links: ctas && ctas.length > 0 ? ctas.map(c => c.link) : [],
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    bodyContentRef,
    extendedGaTrackData
  );

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: navigateTo?.link || '',
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <LinkOrTag
      className="text-puma-black relative"
      ref={bodyContentRef}
      href={navigateTo?.link}
      gaBannerData={gaTrackData}
      ga4Data={ga4Data}
      dataTestId="body-content"
      linkType={linkType!}
    >
      <div
        className={`flex flex-col items-${hAlign} text-${horizontalAlignment} mobile:space-y-1 tablet:space-y-2 desktop:space-y-3`}
      >
        {logo?.asset?.url && (
          <div className="relative">
            <img
              src={logo?.asset?.url}
              alt={logo?.alt || ''}
              width={logo.asset?.metadata?.dimensions?.width}
              height={logo.asset?.metadata?.dimensions?.height}
            />
          </div>
        )}
        {header && (
          <ContentHeading
            header={header}
            className={
              'text-3xl md:text-4xl font-bold font-display leading-tight'
            }
            headingTag={headingTag}
            dataTestId={'body-content-header'}
          />
        )}
        <Copy copy={copy} countdownClock={countdownClock} />
        {Boolean(ctas?.length) && linkType === SLOT_LINK_TYPE.CTA && (
          <div
            className={`flex flex-wrap justify-${hAlign} flex-gap-4 pb-6 xs:pb-8 pt-2 max-w-screen-sm`}
            data-test-id="body-content-ctas"
          >
            <CallToActions
              ctas={ctas}
              gaBannerData={gaTrackData}
              ga4Data={ga4Data}
            />
          </div>
        )}
      </div>
    </LinkOrTag>
  );
};

type CopyProps = {
  countdownClock?: BodyContentType['countdownClock'];
  copy?: BodyContentType['copy'];
};

const Copy = ({ countdownClock, copy }: CopyProps) => {
  const isCountdownAvailable = useIsCountdownAvailable(
    countdownClock?.availableFrom,
    countdownClock?.availableTo
  );

  // Prioritise rendering the countdown clock over the copy
  if (countdownClock && isCountdownAvailable) {
    return (
      <CountdownClock
        data-test-id="countdown-clock"
        {...countdownClock}
        config={{
          timeUnitClassName: 'text-2xl mobile:text-lg font-normal',
          containerClassName: 'space-x-1 text-puma-black-300',
        }}
      />
    );
  }

  if (!copy) return null;

  return (
    <p className="text-lg font-display max-w-5xl text-puma-grey-100">
      <Markdown content={copy} />
    </p>
  );
};
